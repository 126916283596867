.imgBox{
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    div{
        // width: 400px;
        // height:600px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        align-self: center;
    }
}