.middleColumn {
    // margin: 10px 0 10px 0;
    border-color: rgba(240, 240, 240, 0.8);
    display: flex;
    padding: 0 50px 0 50px;
    justify-content: space-between;


    .className_text {
        width: 50%;
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;

        p {
            font-size: 32px;
            font-weight: 700;
            line-height: 45px;
            padding-top: 10px;
            padding-bottom: 30px;
            color:#1A1A1A;
        }

        span {
            color: #626262;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            text-align: justify;
            width: 70%;
        }
    }
    .middleColumn_img{
        width: 50%;
        display: flex;
        justify-items: center;
        align-self: center;
        transition: all 0.2s;
    }
    .middleColumn_img:hover{
        
        transform:scale(1.05);
     
    }
}
.middleColumn .middleColumn_img{
    justify-content: center;
}