.navHeader{
    width: 100%;
    height: 150px;
    background-color:#fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.5);
    z-index: 100;
    .nav{
        width: 300px;
        display: flex;
        justify-content: space-around;
        font-size: 18px;
        color: #000;
        margin-right: 200px;
    }
   
}
#navnavhead .navhead{
    background-color:"#fff"
}
