.about{
    width: 100%;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    .backImg{
        height: 500px;
        background-image: url("../../../../public/img/guanyuBanner.png");
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .guanyuweike{
        width: 1000px;
        height: 344px;
        background-color: #fff;
        margin-top: -150px;
        border-radius: 20px;
        align-self: center;
        background: linear-gradient( 180deg, #FFFFFF 0%, #FAFCFF 100%);
    }
    .rongyuzizhi{
        display: flex;
        flex-direction: column;
        .rongyuzizhiImg{
            width: 1062px;
            height: 1743px;
            align-self: center;
            background-position: center center;
            border: 1px dashed #666;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url("../../../../public/img/ryzzImg.png");
        }
    }
    .guanyuwomen{
        width: 1000px;
        height: 344px;
        background-color: #fff;
        align-self: center;
        
    }
    .gywmImg{
        height: 561px;
        background-image: url("../../../../public/img/gywmImg.png");
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    
    .img_url{
        width: 100%;
        height: 959px;
        max-width: 100%;
        background-image: url("https://www.yoc.tech/images/bg58.png");
    }
    .img_url2{
        width: 100%;
        height: 959px;
        max-width: 100%;
        background-image: url("https://www.yoc.tech/images/b8.png");
    }

    
    .rongyuTitle{
        color: #666;
        font-size: 18px;
        line-height: 30px;
        margin: 0 auto 20px;
        width: 1000px;
        text-align: center;
    }
    .contentStyle{
        height: 160px;
        color: #fff;
        line-height: 160px;
        text-align: center;
        background: #364d79,
      };
}