.home_img1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .img_url {
        width: 100%;
        height: 900px;
        max-width: 100%;
        background-image: url("../../../../public/img/banner1.png");
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .home_youshi {
        display: flex;
        flex-direction: column;

        .youshi {
            display: flex;
            width: 1200px;
            justify-content: space-around;
            align-self: center;
            margin-bottom: 64px;
            margin-top: 32px;
            .mk {
                width: 250px;
                height: 280px;
                background: #FFFFFF;
                box-shadow: 2px 3px 10px 2px rgba(0, 0, 0, 0.05);
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                img{
                    margin-bottom: 26px;
                }

                .text {
                    font-family: AlibabaPuHuiTi_3_65_Medium;
                    font-size: 24px;
                    color: #1A1A1A;
                    line-height: 33px;
                    letter-spacing: 1px;
                    text-align: left;
                    font-style: normal;
                    
                }

                .text1 {
                    font-size: 16px;
                    line-height: 22px;
                    color: #606060;
                    font-style: normal;
                    margin-top: 7px;
                }
            }
        }

    }

    .home_jianjie {
        width: 1000px;
        align-self: center;
        display: flex;
        flex-direction: column;
       
    }
}