.middleTitle {
    margin: 0 auto;
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
        font-size: 35px;
        font-weight: 700;
        line-height: 50px;
        padding-top: 10px;
        padding-bottom: 15px;
        color: #000;
        margin: 0 auto;
        text-align: center;
        letter-spacing: 10px;

        
    }
    .title_icon_yellow {
        width: 20px;
        height: 3px;
        background: -webkit-gradient(linear, left top, right top, from( #FFDC5F), to(#FFDC5F));
        background: -webkit-linear-gradient(left, #FFDC5F 0%, #FFDC5F 100%);
        background: linear-gradient(90deg, #FFDC5F 0%, #FFDC5F 100%);
        margin-bottom: 40px;
        text-align: center;
    }
    .title_icon_blue {
        width: 60px;
        height: 3px;
        background: -webkit-gradient(linear, left top, right top, from(#1D68FF), to(#1D68FF));
        background: -webkit-linear-gradient(left, #1D68FF 0%, #1D68FF 100%);
        background: linear-gradient(90deg, #1D68FF 0%, #1D68FF 100%);
        margin-bottom: 40px;
        text-align: center;
    }
}