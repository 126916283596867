.business{
    width: 100%;
    margin-top: 150px;
    .backImg{
        height: 260px;
        background-image: url("../../../../public/img/yewuImg.png");
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .p1{
            font-size: 36px;
            color: #fff;
            line-height: 50px;
        }
        .p2{
            font-size: 24px;
            color: #C2D8FF;
            line-height: 33px;
        }
    }
}